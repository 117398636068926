<!-- 
author:张洪志
description: 基金详情-经理
 -->
<template>
<section class="fund-detail-2" title="基金经理">
  <div class="title">
    <span class="orange ico ico-block"></span>
    <span>基金经理</span>
  </div>
  <div class="flex-between person" v-for="(item,index) in nowManager" :key="item.personalCode + index">
    <div class="person-pic">
      <img :src="item.photoUrl" alt="基金经理">
    </div>
    <div class="person-info">
      <div class="name">{{item.chineseName}}</div>
      <div class="small job-date">
        <span class="gray">从业日期: </span>
        <span> {{item.accessionDate}}</span>
      </div>
      <div class="big-txt">
        <span>{{item.background}}</span>
        <span class="red btn" @click="spreadPersonInfo(item)">{{item.showTxt}}</span>
      </div>
      <div class="work-info">
        <p class="title">· 在任信息</p>
        <div class="flex-between content">
          <div>
            <p class="value">{{item.chiname}}</p>
            <p class="gray small">{{item.accessionDate}} ~ {{item.dimissiondate || '至今'}}</p>
          </div>
          <div>
            <p class="value">{{item.managementTime | managementtimeFilter }}</p>
            <p class="gray small">任期</p>
          </div>
          <div>
            <p class="value red" v-if="item.performance>0"><b>{{ item.performance }}</b> %</p>
            <p class="value green" v-else><b>{{ item.performance }} </b> %</p>
            <p class="gray small">任期回报</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="title" title="历任经理">
    <span class="orange ico ico-block"></span>
    <span>历任经理</span>
  </div>
  <div class="table">
    <p class="head gray-bg flex-between">
      <span>基金经理</span>
      <span>任期开始</span>
      <span>任期结束</span>
    </p>
    <p class="flex-between" v-for="(item,index) in  previousManager" :key="item.personalCode+index">
      <span>{{item.personalName}}</span>
      <span>{{item.accessionDate}}</span>
      <span>{{item.dimissionDate}}</span>
    </p>
  </div>
</section>
</template>

<script>
import { personList,fundSurvey } from '@/api/fund.js'
export default {
  name: 'FundDetail_2',
  props: {
    fundCode: String,
  },
  data() {
    return {
      nowManager: [],
      previousManager: [],
    }
  },
  filters: {
    managementtimeFilter(val) {
      let ret = "--";
      if (!val) {
        return ret;
      }

      let y = Math.floor(val / 365);
      let d = val % 365;
      return `${y}年又${d}天`;

    },
    performanceFilter(val) {
      let ret = "--";
      if (!val) {
        return ret;
      }
      ret = `${Number(val).toFixed(2)}%`;
      return ret;
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      fundSurvey(this.fundCode).then(res => {
        let nowManager = res.data.managerDTOS
        let previousManager = res.data.preManagerDTO
        nowManager.forEach(m => {
          m.long_background = m.personalText
          m.short_background = (m.personalText||'').substr(0,130)+'…'
          m.background = m.short_background
          m.accessionDate = (m.accessionDate||'').substr(0,10)
          m.showLong = false
          m.showTxt = '展开'
        })
        this.nowManager = nowManager;
        this.previousManager = previousManager;
      })
    },
    spreadPersonInfo(item) {
      item.showLong = !item.showLong
      if(item.showLong) {
        item.showTxt = '收起'
        item.background = item.long_background
      } else {
        item.showTxt = '展开'
        item.background = item.short_background
      }
    }
  }
}
</script>

<style lang="less" scoped>
@gray: #999;
@gray-bg: #F3F4F6;
@orange: #CE9B63;
@blue: #00aaff;
@red: #D43F3F;
@green: #01B834;

.gray {
  color: @gray;
}

.gray-bg {
  background: @gray-bg;
}

.orange {
  color: @orange;
}

.blue {
  color: @blue;
}

.red {
  color: @red;
}

.green {
  color: @green;
}

.btn-orange-light {
  background: #FFF5EA;
  color: #C59660;
  border-radius: 3px;
}

.small {
  font-size: 12px;
}

.title {
  font-size: 20px;
  line-height: 3em;
  margin-top: 10px;

  .ico {
    margin: 0 5px;
  }
}

.person {
  margin: 1em auto 2em auto;
}

.person-pic,
.person-pic img {
  width: 136px;
  height: 190px;
}

.person-info {
  width: 980px;
  border-bottom: 1px solid #eee;

  .name {
    font: 18px microsoft yahei;
  }

  .job-date {
    line-height: 3em;
  }
}

.work-info {
  margin-top: 10px;
  width: 80%;

  .title {
    font: bold 15px "microsoft yahei";
  }

  .content {
    text-align: center;
    margin-top: 10px;

    &>div:first-child {
      text-align: left;
      padding-left: 0.5em;
      width: 50%;
    }

    .value {
      font: 16px "microsoft yahei";
    }

    .small {
      line-height: 3em;
    }
  }
}

.big-txt {
  line-height: 1.8em;
}

.table {
  text-align: center;
  font-size: 16px;
  line-height: 3em;
  box-shadow: 0 4px 8px #ddd;
  margin-bottom: 40px;

  p {
    border-bottom: 1px solid #ddd;

    &.head {
      border: none;
    }

    span {
      width: 33%;
    }
  }
}
</style>
