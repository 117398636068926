import request from '@/utils/request'

/** 获取基本信息 */
export function fundBaseInfo(data) {
  return request({
    url: '/api/sale/prodDetails/baseInfo',
    method: 'post',
    data
  })
}

//  查询基金概况（包含基金详情，在任经理和离任经理列表，重仓股票，分红配置）
export function fundSurvey(data) {
  return request({
    url: "/api/sale/prodDetails/fundOverview",
    method: "POST",
    data
  });
}


//  查询基金概况（包含资产配置明细集合，行业配置明细集合）
export function fundZcHy(data) {
  return request({
    url: "/api/sale/prodDetails/fundZcHy",
    method: "POST",
    data
  });
}

// 获取公告详情
export function getFundAnnViewDetail(data) {
  return request({
    url: '/api/sale/prod/fundBase/fundAnnViewDetail',
    method: 'POST',
    data
  })
}
// 财务报表--利润
export function finstaFinInc(data) {
  return request({
    url: '/api/sale/platfcfg/finsta/finInc',
    method: 'POST',
    data
  })
}
//  获取投资组合信息
export function getPortfolio(data) {
  return request({
    url: '/api/sale/prod/details/portfolio',
    method: 'POST',
    data
  })
}
//  获取重仓股配置明细
export function getAwnstocks(data) {
  return request({
    url: '/api/sale/prod/details/awnstocks',
    method: 'POST',
    data
  })
}
//  查询产品费率管理分页列表
export function rateMagPage(data) {
  return request({
    url: '/api/system/rateMag/page',
    method: 'POST',
    data
  })
}
//  获取产品交易规则详细信息
export function ruleFind(data) {
  return request({
    url: '/api/system/prod/rule/find',
    method: 'POST',
    data
  })
}

/** 基金财务信息
 * @param {String} secucode 基金代码
 * @param {String} [report_year] 报告年份(yyyy)
 * @param {String} [is_year_end] 是否为年末报告(1:年度)
 */
export function finMainInfo(secucode,report_year,is_year_end) {
  return request({
    url: '/api/sale/platfcfg/finsta/finMainInfo',
    method: 'POST',
    data:{
      secucode,report_year,is_year_end
    }
  })
}