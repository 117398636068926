<!-- 
author:张洪志
description: 基金详情-公司
 -->
<template>
<section class="fund-detail-3" title="基金公司">
    <div class="title">
        <span class="orange ico ico-block"></span>
        <span>基金公司</span>
    </div>
    <div class="head-box flex-between">
        <div class="flex-between">
            <img class="head-img" :src="fundCompany.photoUrl" alt="基金公司">
            <div>
                <p class="name">{{fundCompany.investAdvisorname}}</p>
                <p>
                    <span class="gray">起始时间 </span>
                    <span>{{fundCompany.establishmentDate}}</span>
                </p>
            </div>
        </div>
        <div class="flex-between">
            <div>
                <big>{{fundCompany.assetmgtRange }}</big>
                <p class="gray">管理规模(亿元)</p>
            </div>
            <div>
                <big class="red">{{fundCompany.numberofFunds}}</big>
                <p class="gray">旗下基金(支)</p>
            </div>
        </div>
    </div>
    <div class="table">
        <dl class="flex-between">
            <dt>法人代表</dt>
            <dd>{{fundCompany.legalrepr}}</dd>
            <dt>总经理</dt>
            <dd>{{fundCompany.generalmanager}}</dd>
        </dl>
        <dl class="flex-between">
            <dt>电子邮箱</dt>
            <dd>{{fundCompany.email}}</dd>
            <dt>联系电话</dt>
            <dd>{{fundCompany.tel}}</dd>
        </dl>
        <dl class="flex-between">
            <dt>传真</dt>
            <dd>{{fundCompany.fax}}</dd>
            <dt>网站地址</dt>
            <dd>{{fundCompany.website}}</dd>
        </dl>
        <dl class="flex-between">
            <dt>办公地址</dt>
            <dd style="width:auto;">{{fundCompany.areaCode}}</dd>
        </dl>
    </div>
</section>
</template>

<script>
import { fundSurvey } from '@/api/fund.js'
export default {
    name: 'FundDetail_3',
    props: {
        fundCode: String,
    },
    data() {
        return {
            fundCompany: {},
        }
    },
    created() {
        this.getDetail()
    },
    methods: {
        getDetail() {
            fundSurvey(this.fundCode).then(res => {
                this.fundCompany = res.data.appSfProdCompanyDTO
            })
        }
    }
}
</script>

<style lang="less" scoped>
@gray: #999;
@gray-bg: #F3F4F6;
@orange: #CE9B63;
@red: #D43F3F;

.gray {
    color: @gray;
}

.gray-bg {
    background: @gray-bg;
}

.orange {
    color: @orange;
}

.red {
    color: @red;
}

.title {
    font-size: 20px;
    line-height: 3em;
    margin-top: 10px;

    .ico {
        margin: 0 5px;
    }
}

.head-box {
    align-items: center;

    &>div {
        width: 50%;
        align-items: center;
    }

    &>div:first-child {
        justify-content: flex-start;
    }

    &>div:last-child {
        text-align: center;
        font-size: 16px;
        line-height: 2.2em;

        &>div {
            width: 50%;
        }

        big {
            font-size: 28px;
        }
    }

    .head-img {
        width: 100px;
        height: 100px;
        border-radius: 100px;
        box-shadow: 0 0 10px #eee;
        margin-right: 30px;
    }

    .name {
        font: bold 20px/2.6em "microsoft yahei";
        border-bottom: 1px solid @orange;
        margin-bottom: 15px;
    }
}

.table {
    line-height: 3em;
    font-size: 15px;
    border: 1px solid #eee;
    border-width: 1px 1px 0 0;
    margin: 40px 0;

    dl {
        border-bottom: 1px solid #eee;

        &:last-child {
            justify-content: flex-start;
        }
    }

    dt {
        .gray-bg();
        width: 6.5em;
        padding: 0 1em;
        text-align: center;
    }

    dd {
        width: 420px;
        padding-left: 1em;
        .gray();
    }
}
</style>
