<!-- 
author:张洪志
description: 基金详情-持仓
 -->
<template>
<section class="fund-detail-4" title="基金持仓">
  <div v-if="awkwardStocksDtl.awnstock && awkwardStocksDtl.awnstock.length > 0 && bondportifolioDTO.bondportifolio && bondportifolioDTO.bondportifolio.length > 0">
    <div class="flex-between">
      <div class="detail-left">
        <div class="title" title="资产配置">
          <span class="orange ico ico-block"></span>
          <span>资产配置</span>
          <small class="gray">{{ reportdate1 }}</small>
        </div>
        <div class="table table-3">
          <p class="head gray-bg flex-between">
            <span>资产分布</span>
            <span>金额(元)</span>
            <span>占总资产比例</span>
          </p>
          <p class="flex-between" v-for="item in assetallocation" :key="item.assettypecode">
            <span>{{item.assettype}}</span>
            <span>{{item.marketvalue | valueFilter}}</span>
            <span>{{ (parseFloat(item.ratiointotalasset||0) * 100).toFixed(2).$trimZero() }}%</span>
          </p>
        </div>
      </div>
      <div class="detail-right">
        <div class="title">
          <span class="gray">截止日期 </span>
          <el-select class="orange" v-model="reportdate1" placeholder="请选择" @change="reportdate1Change" size="mini">
            <el-option v-for="item in reportdate1Arr" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="chart">
          <e-chart :option="pieOption_1" />
          <div class="chart-total" v-if="chartTotal_1">
            <p>{{chartTotal_1}}元</p>
            <p class="gray">资产分布</p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-between">
      <div class="detail-left">
        <div class="title" title="行业分布">
          <span class="orange ico ico-block"></span>
          <span>行业分布</span>
          <small class="gray">{{reportdate2}}</small>
        </div>
        <div class="table table-3">
          <p class="head gray-bg flex-between">
            <span>行业类别</span>
            <span>市值(万元)</span>
            <span>占资产净值比例</span>
          </p>
          <p class="flex-between" v-for="item in industryportifolio" :key="item.industrycode">
            <span>■ {{item.industryname}}</span>
            <span>{{item.marketvalue | wan_valueFilter}}</span>
            <span>{{item.ratioinnv | valueFilter }}%</span>
          </p>
        </div>
      </div>
      <div class="detail-right">
        <div class="title">
          <span class="gray">截止日期 </span>
          <el-select class="orange" v-model="reportdate2" placeholder="请选择" @change="reportdate2Change" size="mini">
            <el-option v-for="item in reportdate2Arr" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="chart" v-if="pieOption_2.series[0].data.length">
          <e-chart :option="pieOption_2" />
          <div class="chart-total" v-if="chartTotal_2">
            <p>{{chartTotal_2}}元</p>
            <p class="gray">资产分布</p>
          </div>
        </div>
      </div>
    </div>
    <div title="重仓股票">
      <div class="title flex-between">
        <p>
          <span class="ico ico-block orange"></span>
          <span>重仓股票</span>
        </p>
        <p class="right">
          <small class="gray">数据时间 </small>
          <small class="orange">{{awkwardStocksDtl.reportdate}}</small>
        </p>
      </div>
      <div class="volume-chart-box" v-if="pieOption_3.series[0].data.length">
        <e-chart :width="1000" :height="300" :option="pieOption_3" />

      </div>
      <div class="table table-5">
        <p class="head gray-bg flex-between">
          <span>股票代码</span>
          <span style="width:40%">股票名称</span>
          <span>占净值比例</span>
          <span>持有股数(万)</span>
          <span>持仓市值(万元)</span>
        </p>
        <p class="flex-between" v-for="item in awkwardStocksDtl.awnstock" :key="item.stocksecucode">
          <span>{{item.stocksecucode}}</span>
          <span style="width:40%">{{item.stockchiname}}</span>
          <span>{{(item.ratioinnv||'')| valueFilter}}%</span>
          <span>{{item.sharesholding | wan_valueFilter }}</span>
          <span>{{item.marketvalue | wan_valueFilter}}</span>
        </p>
      </div>
    </div>
    <div title="债券持仓">
      <div class="title flex-between">
        <p>
          <span class="ico ico-block orange"></span>
          <span>债券持仓</span>
        </p>
        <p class="right">
          <small class="gray">数据时间 </small>
          <small class="orange">{{bondportifolioDTO.reportdate}}</small>
        </p>
      </div>
      <div class="table table-4">
        <p class="head gray-bg flex-between">
          <span>债券代码</span>
          <span style="width:50%">债券名称</span>
          <span>占净值比例</span>
          <span>持仓市值(万元)</span>
        </p>
        <p class="flex-between" v-for="item in bondportifolioDTO.bondportifolio" :key="item.bondsecucode">
          <span>{{item.bondsecucode}}</span>
          <span style="width:50%">{{item.bondchiname}}</span>
          <span>{{(item.ratioinnv||'')| valueFilter}}%</span>
          <span>{{item.marketvalue | wan_valueFilter}}</span>
        </p>
      </div>
    </div>
  </div>
  <div class="no-data" v-else></div>
</section>
</template>

<script>
let numeral = require('numeral');
numeral.defaultFormat('0,0.00');
import {
  fundSurvey,
  fundZcHy,
} from '@/api/fundDetail'
import eChart from '@/components/eChart.vue'
export default {
  name: 'FundDetail_4',
  components: {
    eChart
  },
  props: {
    fundCode: String,
  },
  filters: {
    wan_valueFilter(val) {
      let ret = "--";
      if (!val) {
        return ret;
      }
      val = val / 10000;
      ret = numeral(val).format();
      return ret;
    },
    valueFilter(val) {
      let ret = "--";
      if (!val) {
        return ret;
      }
      ret = numeral(val).format();
      return ret;
    },
  },
  data() {
    return {
      pieOption_1: {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '资产配置',
            type: 'pie',
            radius: ['40%', '70%'],
            data: []
          }
        ]
      },
      pieOption_2: {
        tooltip: {
          trigger: 'item'
        },
        series: [{
          name: '行业分布',
          type: 'pie',
          radius: ['40%', '70%'],
          data: []
        }]
      },
      pieOption_3: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 'center',
          left: 'right',
          orient: 'vertical'
        },
        series: [{
          name: '重仓股票',
          type: 'pie',
          radius: ['40%', '70%'],
          data: []
        }]
      },

      assetallocationDTO: [],
      industryportifolioDTO: [],
      awkwardStocksDtl: {},
      bondportifolioDTO: {},
      reportdate1: "",
      reportdate1Arr: [],
      reportdate2: "",
      reportdate2Arr: []

    }
  },
  computed: {
    assetallocation() {
      let ret = [];
      try {
        let reportdate1 = this.reportdate1;
        const obj = this.assetallocationDTO.filter(v => v.reportdate == reportdate1)[0];
        ret = obj.assetallocation;
        ret = ret.filter(m => m.assettype !== '资产净值')
      } catch (error) {
        ret = [];
      }
      return ret;
    },
    industryportifolio() {
      let ret = [];
      try {
        let reportdate2 = this.reportdate2;
        const obj = this.industryportifolioDTO.filter(v => v.reportdate == reportdate2)[0];
        ret = obj.industryportifolio;
      } catch (error) {
        ret = [];
      }
      return ret;
    },
    pieOption_1_Data() {
      let retArr = this.assetallocation.map(v => {
        return {
          name: v.assettype,
          value: v.marketvalue
        }
      })
      retArr = retArr.filter(m => m.name !== '资产净值' && m.name !== '资产总计')
      return retArr;

    },
    chartTotal_1() {
      let value = this.assetallocation.find(m => m.assettype === '资产总计').marketvalue
      return parseFloat(value||0).$formatBig()
    },
    pieOption_2_Data() {
      let retArr = this.industryportifolio.map(v => {
        return {
          name: v.industryname,
          value: v.marketvalue
        }
      })
      retArr = retArr.filter(m => m.name !== '行业投资合计')
      return retArr;

    },
    chartTotal_2() {
      let value = this.industryportifolio.find(m => m.industryname === '行业投资合计').marketvalue
      return parseFloat(value||0).$formatBig()
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      let params = {
        fundCode: this.fundCode
      };
      fundSurvey(params).then(res => {
        const {
          code,
          data: {
            awkwardStocksDtl,
            bondportifolioDTO
          }

        } = res;
        if (code == 200) {
          this.awkwardStocksDtl = awkwardStocksDtl;
          this.bondportifolioDTO = bondportifolioDTO;
          if (awkwardStocksDtl) {
            const {
              awnstock
            } = awkwardStocksDtl;
            const retArr = awnstock.map(item => {
              return {
                value: item.marketvalue,
                name: item.stockchiname
              }
            })
            this.$set(this.pieOption_3.series[0], "data", retArr);
          }

        }
      });
      // 资产报告
      let params2 = {
        fundCode: this.fundCode,
        // fundCode: "010343",
      };
      fundZcHy(params2).then(res => {
        const {
          code,
          data: {
            assetallocationDTO,
            industryportifolioDTO,
          }
        } = res;
        if (code == 200) {
          // 资产配置
          this.assetallocationDTO = assetallocationDTO;
          this.reportdate1Arr = [];
          assetallocationDTO.forEach(item => {
            this.reportdate1Arr.push(item.reportdate);
          });
          // 默认第一个
          this.reportdate1 = this.reportdate1Arr[0];
          this.$set(this.pieOption_1.series[0], "data", this.pieOption_1_Data);
          // 行业报告
          this.industryportifolioDTO = industryportifolioDTO;
          this.reportdate2Arr = [];
          industryportifolioDTO.forEach(item => {
            this.reportdate2Arr.push(item.reportdate);
          });
          // 默认第一个
          this.reportdate2 = this.reportdate2Arr[0];
          this.$set(this.pieOption_2.series[0], "data", this.pieOption_2_Data);
        }
      })
    },
    reportdate1Change(val) {
      this.$set(this.pieOption_1.series[0], "data", this.pieOption_1_Data);
    },
    reportdate2Change(val) {
      this.$set(this.pieOption_2.series[0], "data", this.pieOption_2_Data);

    }
  }
}
</script>

<style lang="less" scoped>
@gray: #999;
@gray-bg: #F3F4F6;
@orange: #CE9B63;
@blue: #00aaff;
@red: #D43F3F;

.gray {
  color: @gray;
}

.gray-bg {
  background: @gray-bg;
}

.orange {
  color: @orange;
}

/deep/.el-input__inner {
  border: none;
  .orange();
}

/deep/.el-select .el-input .el-select__caret {
  .orange();
}

.blue {
  color: @blue;
}

.red {
  color: @red;
}

.btn-orange-light {
  background: #FFF5EA;
  color: #C59660;
  border-radius: 3px;
}

.small {
  font-size: 12px;
}

.title {
  font-size: 20px;
  line-height: 3em;
  margin-top: 10px;

  .ico {
    margin: 0 5px;
  }

  small {
    font-size: 14px;
    margin-left: 1em;
  }
}

.detail-left {
  width: 750px;
}

.detail-right {
  width: 380px;

  select {
    border: none;
  }

  .title {
    font-size: 15px;
    text-align: right;
  }

  .chart {
    min-height: 280px;
    margin-top: 20px;
    background: #fefefe;
    position: relative;
  }
  .chart-total{
    position: absolute;
    top:44%;
    left:42%;
  }
}

.volume-chart-box {
  height: 300px;
}

.table {
  text-align: center;
  font-size: 16px;
  line-height: 3em;
  box-shadow: 0 4px 8px #ddd;
  margin: 10px auto 30px auto;

  p {
    border-bottom: 1px solid #ddd;

    &.head {
      border: none;
    }
  }
}

.table-3>p>span {
  width: 33%;

  &:first-child {
    text-align: left;
    padding-left: 1em;
  }
}

.table-4>p>span {
  width: 25%;
}

.table-5>p {
  border: none;

  &:nth-child(odd) {
    background: #f6f6f6;
  }

  &:first-child {
    background: #ddd;
  }

  &>span {
    width: 20%;
  }
}

.no-data {
  background: url(../../../static/img/fund-market/no-fund.png) no-repeat center center;
  height: 432px;
}
</style>
