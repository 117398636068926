<!-- 
author:张洪志
description:基金历史净值
 -->
<template>
  <section class="fund-values" v-if="visible">
    <div class="vw">
      <div class="title">{{fundName}}</div>
      <div class="sub-title">历史净值明细</div>
      <span class="close btn ico-x" @click="toggleVisible(false)"></span>
      <div class="table">
        <p class="head flex-between">
          <span>日期</span>
          <span>单位净值</span>
          <span>累计净值</span>
          <span>日涨幅</span>
        </p>
        <p class="flex-between" v-for="item in fundValues" :key="item.tradingDay">
          <span>{{item.tradingDay}}</span>
          <span>{{item.unitnv}}</span>
          <span>{{item.accuunitnv}}</span>
          <span :class="item.isGreen ? 'green' : 'red'">{{item.changePct}}%</span>
        </p>
      </div>
      <div class="page-bar">
        <page-bar :display="5" :total="pageTotal" @click="goPage" />
      </div>
    </div>
  </section>
</template>

<script> 
import PageBar from '@/components/Pagebar.vue'
import {netValueList} from '@/api/fund.js'
export default {
  name: 'FundValues',
  components: {
    PageBar
  },
  props: {
    fundCode: {
      type: String,
      required: true 
    },
    fundName: {
      type: String,
      required: true 
    }
  },
  data() {
    return {
      visible: false,
      fundValues:[],
      pageNum:1,
      pageTotal:0
    }
  },
  created() {
    //this.stageIncome()
  },
  methods: {
    toggleVisible(show) {
      this.visible = show
    },
    stageIncome() {
      netValueList(this.fundCode,null,this.pageNum,10,false).then(result => {        
        result.data.data.forEach(m => {
          m.tradingDay = (m.tradingDay||'')
          m.unitnv = m.unitnv.$trimZero()
          m.accuunitnv = m.accuunitnv.$trimZero()
          m.changePct = m.changePct ? parseFloat(m.changePct).toFixed(2) : '--'
          m.isGreen = parseFloat(m.changePct) < 0
        })
        this.fundValues = result.data.data
        this.pageTotal = result.data.totalPage
      })
    },
    goPage(page) {
      this.pageNum = page
      this.stageIncome()
    }
  }
}
</script>

<style lang="less" scoped>
  .green{color:#40aa0f;}
  .red{color:#ef0b0b;}
  .fund-values{ width: 100%; min-height: 100%; background: rgba(255,255,255,0.5); position:fixed; top:0; left: 0; z-index:3;
    .vw{width:900px; padding:30px 50px; position: relative; box-shadow: 0 0 10px #ddd; margin-top:5%;
      .title{font:bold 22px/1.5em "microsoft yahei";}
      .sub-title{ font-size:16px; color:#DEB87B; margin:10px auto 20px auto;}
      .close{font-size:30px; color:#ccc; position: absolute; right:50px; top:40px;}
      .table{ line-height: 3em; color:#686868; text-align: center;
        .head{ background: #F3F4F6;}
        p{border-bottom: 1px solid #ddd;}
        p>span{ width:25%;}
      }
      .page-bar{ padding:20px; text-align: right;}
    }
  }
</style>
