<!-- 
author:张洪志
description: 基金详情-财务指标
 -->
<template>
  <section class="fund-detail-8" title="财务指标">
    <div class="title">
      <span class="orange ico ico-block"></span>
      <span>财务指标</span>
    </div>
    <div class="report-box" v-for="item in dataList" :key="item.year">
      <div class="name">关于 {{item.fundName}} {{item.year}}年 季度报告</div>
      <div class="flex-between">
        <div>
          <span class="gray">发布时间 2020-08-08</span>
          <span class="orange spread btn" @click="spreadInfo(item)">{{ item.show ? '收起' : '展开' }}</span>
        </div>
        <div><span :class="['btn gray iconfont', item.show ? 'icon-a-fundmarket-packup' : 'icon-a-fundmarket-unfold']" @click="spreadInfo(item)"></span></div>
      </div>
      <div class="table flex-between" v-show="item.show">
        <p>
          <span>季度报告</span>
          <span>报告日期</span>
          <span>本期已实现收益(元)</span>
          <span>加权平均基金份额本期利润</span>
          <span>本期加权平均净值利润率</span>
          <span>本期基金份额净值增长</span>
          <span>期末可供分配利润</span>
          <span>期末可供分配基金份额利润</span>
          <span>期末基金资产净值</span>
          <span>期末基金份额净值</span>
          <span>基金份额累计净值增长率</span>
        </p>
        <p v-for="item2 in item.datas" :key="item2.enddate">
          <span>第{{item2.quarter}}季度报告</span>
          <span>{{item2.enddate}}</span>
          <span>{{item2.totalprofit || '--'}}</span>
          <span>{{item2.totalprofitpershare || '--'}}</span>
          <span>{{item2.wanvprofitrate || '--'}} %</span>
          <span>{{0 || '--'}}</span>
          <span>{{item2.distributableprofits || '--'}}</span>
          <span>{{item2.distriprofitspshare || '--'}}</span>
          <span>{{item2.netassetsvalue || '--'}}</span>
          <span>{{item2.nvpershare || '--'}}</span>
          <span>{{item2.unitaccumulativenvgr || '--'}} %</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { finMainInfo } from '@/api/fundDetail';
export default {
  name: 'FundDetail_8',
  props: {
    fundCode: String
  },
  data() {
    return {
      dataList: []
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      finMainInfo(this.fundCode).then(result => {
        if(!result.data || result.data.length === 0) {
          return
        }
        let years = []
        let data = result.data
        for (let i in data) {
          let item = data[i]
          if(!years.includes(item.report_year)) {
            years.push(item.report_year)
          }
        }
        let dataGroup = []
        for (let i in years) {
          let year = years[i]
          let datas = data.filter(m => m.report_year === year)
          datas.forEach(m => {
            let month = parseInt(m.enddate.substr(5,2))
            m.quarter = month === 3 ? '一' : month === 6 ? '二' : month === 9 ? '三' : month === 12 ? '四' : ''
          })
          let show = i === 0
          dataGroup.push({
            fundName: datas[0].chiname,
            year,
            datas,
            show
          })
        }
        this.dataList = dataGroup
      })
    },
    /** 收缩、展开 */
    spreadInfo(item) {
      item.show = !item.show;
      item.showTxt = item.show ? '收起' : '展开';
    }
  }
};
</script>

<style lang="less" scoped>
@gray: #999;
@gray-bg: #f3f4f6;
@orange: #ce9b63;
@blue: #00aaff;
@red: #d43f3f;

.gray {
  color: @gray;
}

.gray-bg {
  background: @gray-bg;
}

.orange {
  color: @orange;
}

.blue {
  color: @blue;
}

.red {
  color: @red;
}

.btn-orange-light {
  background: #fff5ea;
  color: #c59660;
  border-radius: 3px;
}

.small {
  font-size: 12px;
}

.title {
  font-size: 20px;
  line-height: 3em;
  margin-top: 10px;

  .ico {
    margin: 0 5px;
  }
}

.report-box {
  box-shadow: 0 0 10px #eee;
  padding: 20px 30px;
  margin-bottom: 30px;

  .name {
    font: bold 18px/1.6em 'microsoft yahei';
    margin-bottom: 10px;
  }

  .spread {
    margin-left: 2em;
  }
}

.table {
  text-align: center;
  font-size: 16px;
  line-height: 2.9em;
  margin-top: 40px;
  border: solid @orange;
  border-width: 1px 0 0 1px;

  p{    
    width: 50%;
  }

  p:first-child {
    .btn-orange-light();
  }

  p > span {
    display: block;
    border: solid @orange;
    border-width: 0 1px 1px 0;
  }
}
</style>
