<!-- 
author:张洪志
description: 基金详情-分红
 -->
<template>
  <section class="fund-detail-7" title="基金分红">
    <div v-if="dividendsInfo && dividendsInfo.length > 0">
      <div class="title">
        <span class="orange ico ico-block"></span>
        <span>分红配送</span>
      </div>
      <div class="table">
        <p class="head gray-bg flex-between">
          <span>分红日期</span>
          <span>每份分红金额</span>
        </p>
        <p class="flex-between" v-for="item in dividendsInfo" :key="item.id">
          <span>{{ item.payDate }}</span>
          <span>{{ item.eachDividendAmount }}元</span>
        </p>
      </div>
    </div>
    <div class="no-data" v-if="!dividendsInfo || dividendsInfo.length === 0"></div>
  </section>
</template>

<script>
import { pofShare } from '@/api/fund.js';
export default {
  name: 'FundDetail_7',
  props: {
    fundCode: String
  },
  data() {
    return {
      dividendsInfo: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      pofShare(this.fundCode).then(res => {
        this.dividendsInfo = res.data.data
      });
    }
  }
};
</script>

<style lang="less" scoped>
@red: #d43f3f;
@gray-bg: #f3f4f6;
@orange: #ce9b63;

.red {
  color: @red;
}

.gray-bg {
  background: @gray-bg;
}
.orange {
  color: @orange;
}
.title {
  font-size: 20px;
  line-height: 3em;
  margin-top: 10px;

  .ico {
    margin: 0 5px;
  }
}

.table {
  text-align: center;
  font-size: 15px;
  line-height: 4em;
  box-shadow: 0 4px 8px #ddd;
  margin-bottom: 40px;

  p {
    border-bottom: 1px solid #eee;

    &.head {
      border: none;
      font-size: 14px;
      line-height: 3.5em;
    }

    span {
      width: 50%;
    }
  }
}

.no-data {
  background: url(../../../static/img/fund-market/no-rate.png) no-repeat center center;
  height: 432px;
}
</style>
