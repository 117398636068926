<!-- 
author:张洪志
description: 基金详情-概况
 -->
<template>
<section class="fund-detail-1" title="基金概况">
    <div class="title">
        <span class="orange ico ico-block"></span>
        <span>基金概况</span>
    </div>
    <div class="table2">
        <dl class="flex-between">
            <dt>基金名称</dt>
            <dd>{{fundBase.productName}}</dd>
            <dt>基金简称</dt>
            <dd>{{ fundBase.productName }}</dd>
        </dl>
        <dl class="flex-between">
            <dt>产品代码</dt>
            <dd>{{fundBase.regnum}}</dd>
            <dt>发行日期</dt>
            <dd>{{fundBase.startdate}}</dd>
        </dl>
        <dl class="flex-between">
            <dt>--</dt>
            <dd></dd>
            <dt>产品类型</dt>
            <dd>{{fundBase.fundType}}</dd>
        </dl>
        <dl class="flex-between">
            <dt>基金管理人</dt>
            <dd>{{fundBase.investadvisorName}}</dd>
            <dt>基金托管人</dt>
            <dd>{{fundBase.trusteeName}}</dd>
        </dl>
        <dl class="flex-between">
            <dt>基金经理</dt>
            <dd>{{fundBase.fundMember}}</dd>
            <dt>风险等级</dt>
            <dd>{{fundBase.fundRisk}}</dd>
        </dl>
        <dl class="flex-between">
            <dt>业绩评价标准</dt>
            <dd>{{details.performdrawrateDesc}}</dd>
            <dt>基金管理费</dt>
            <dd>{{listData.managerRate&&listData.managerRate[0]||''}}</dd>
        </dl>
        <dl class="flex-between">
            <dt>基金托管费</dt>
            <dd>{{listData.trusteeRate&&listData.trusteeRate[0]||''}}</dd>
            <dt>销售服务费</dt>
            <dd>{{listData.saleRate&&listData.saleRate[0]||''}}</dd>
        </dl>
    </div>
    <div class="small gray" style="padding-top: 1em;">
        <span>管理费和托管费直接冲基金产品中扣除，具体计算方法及费率结构请参见 </span>
        <a class="blue">基金《招募说明书》</a>
    </div>
    <div class="title">
        <span class="orange ico ico-block"></span>
        <span>投资目标</span>
    </div>
    <div class="big-txt">
        <span>{{fundBase.investtarget}}</span>
    </div>
    <div class="title">
        <span class="orange ico ico-block"></span>
        <span>投资理念</span>
    </div>
    <div class="big-txt gray">
        <span class="gray">{{fundBase.riskreturncharacter}}</span>
    </div>
    <div class="title">
        <span class="orange ico ico-block"></span>
        <span>投资范围</span>
    </div>
    <div class="big-txt gray">
        <span>
            {{fundBase.investfield}}
        </span>
    </div>
    <div class="title">
        <span class="orange ico ico-block"></span>
        <span>投资策略</span>
    </div>
    <div class="big-txt gray">
        <span>
            {{fundBase.investStrategy}}
        </span>
    </div>
    <div class="title">
        <span class="orange ico ico-block"></span>
        <span>分红政策</span>
    </div>
    <div class="big-txt gray">
        <span>
            {{fundBase.profitdistributionrule}}
        </span>
    </div>
</section>
</template>

<script>
import { fundSurvey,privateDetail ,fundTradeRule} from '@/api/fund.js'
import {getDicts} from '@/api/dict.js'
// import {fundTradeRule,} from '@/api/fund.js'

export default {
    name: 'FundDetail_1',
    props: {
        fundCode: String,
    },
    data() {
      return {
        riskLevels:[],
        fundTypes:[],
        fundBase: {},
        listData:{},
        details:{}
      }
    },
    created() {
      this.getRiskLevel().then(() => {
        this.getFundType()
        this.getDetail()
      })
    },
    methods: {
        /** 获取基金风险级别 */
        getRiskLevel() {
          return getDicts('sys_fund_risklevel').then(result => {
            if(!result.data) {
              return
            }
            this.riskLevels = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
          })
        },
        /** 获取产品类型 */
        getFundType() {
          return getDicts('ofund_type').then(result => {
            if(!result.data) {
              return
            }
            this.fundTypes = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
          })
        },
        getDetail() {
          fundSurvey(this.fundCode).then(res => {
            let data = res.data
            this.fundBase = data.appSfProdBaseDTO || {};
            this.fundBase.fundType = this.fundTypes.find(m => m.code === this.fundBase.productType).name
            this.fundBase.fundRisk = this.riskLevels.find(m => m.code === this.fundBase.riskLevel).name
          })
          fundTradeRule(this.fundCode).then(res=>{
            this.listData=res.data
            })
          privateDetail(this.fundCode).then(res=>[
              this.details=res.data
          ])
        }
    },
}
</script>

<style lang="less" scoped>
@gray: #bbb;
@gray-bg: #F3F4F6;
@orange: #CE9B63;
@blue: #00aaff;

.gray {
    color: @gray;
}

.gray-bg {
    background: @gray-bg;
}

.orange {
    color: @orange;
}

.blue {
    color: @blue;
}

.btn-orange-light {
    background: #FFF5EA;
    color: #C59660;
    border-radius: 3px;
}

.small {
    font-size: 12px;
}

.title {
    font-size: 20px;
    line-height: 3em;
    margin-top: 10px;

    .ico {
        margin: 0 5px;
    }
}

.table2 {
    line-height: 3em;
    font-size: 15px;
    border: 1px solid #eee;
    border-width: 1px 1px 0 0;

    dl {
        border-bottom: 1px solid #eee;
    }

    dt {
        .gray-bg();
        width: 6.5em;
        padding: 0 1em;
    }

    dd {
        width: 460px;
        padding-left: 1em;
    }
}

.big-txt {
    line-height: 2em;
    text-indent: 2em;
}
</style>
